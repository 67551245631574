$(function() {

    Handlebars.registerHelper('nl2br', function(object) {
        return new Handlebars.SafeString(
            (object + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2')
        );
    });

    // Models
    var search = new Search();
    var cart   = new Cart();

    // Templates
    var makes_template         = Handlebars.compile($("#makes-template").html());
    var models_template        = Handlebars.compile($("#models-template").html());

    // Get a message handler for the element
    var get_messages = function(elem)
    {
        var messages = elem.data('messages');

        if(messages === undefined)
        {
            messages = new Messages($('<div></div>').insertAfter(elem));
            elem.data('messages', messages);
        }

        return messages;
    };

    // Updates the makes for the selected year
    $(document).on('change', '#year', function(){
        var year = $(this).val();

        search.makes(year, {
            messages: get_messages($(this)),
            success: function(data)
            {
                $('#make')
                    .html(makes_template({makes: data}))
                    .trigger("liszt:updated");

                $('#make').trigger('change');
            }
        });
    });

    // Updates the models for the selected make
    $(document).on('change', '#make', function(){
        var year    = $('#year').val();
        var make_id = $(this).val();

        search.models(year, make_id, {
            messages: get_messages($(this)),
            success: function(data)
            {
                $('#model')
                    .html(models_template({models: data}))
                    .trigger("liszt:updated")
                    .trigger('change');
            }
        });
    });

    // Add a vehicle
    $(document).on('click', '.vehicle-add-submit > .submit', function(e) {
        e.preventDefault();

        var add_row = $(this).closest('.vehicle-add');

        var make_id  = $('#make').val();
        var model_id = $('#model').val();
        var year     = $('#year').val();

        cart.new_with_vehicle(make_id, model_id, year, {
            messages: get_messages(add_row),
            success: function(data) {
                location.href = document.base_url+'/search';
            }
        });
    });

    // Initialize the page
    $('#year, #make, #model').select2({
        theme: "bootstrap",
        selectOnClose: true
    });

    $('#year').on('select2:close', function() {
        $('#make').focus();
    });

    $('#make').on('select2:close', function() {
        $('#model').focus();
    });

    $('#model').on('select2:close', function() {
        $('#add-vehicle').focus();
    });

    $('#year').focus();
    $('#year').trigger('change');
    $('#make').trigger('change');
});